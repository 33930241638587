import React, { memo } from "react"
import PreviewCases from "src/components/HomePage/components/PreviewCases/PreviewCases"
import classes from "./OurWorks.module.scss"
import OurWorksCarousel from "src/components/Carousel/OurWorksCarousel/OurWorksCarousel"
import MediaQuery from "react-responsive"

const DynamicOurWorks = () => {
  return (
    <>
      <MediaQuery minWidth={768}>
        <PreviewCases />
      </MediaQuery>
      <MediaQuery maxWidth={767}>
        <OurWorksCarousel MultiCarouselClassName={classes.carousel} />
      </MediaQuery>
    </>
  )
}

export default memo(DynamicOurWorks)
