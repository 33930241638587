import React from "react"
import MultiCarousel from "../MultiCarousel/MultiCarousel"
import OurTeamCarouselItem from "./OurTeamCarouselItem/OurTeamCarouselItem"
import ourTeam from "src/images/ourTeam/img.jpg"
import ourTeam1 from "src/images/ourTeam/img-1.jpg"
import ourTeam2 from "src/images/ourTeam/img-2.jpg"
import ourTeam4 from "src/images/ourTeam/img-4.jpg"
import ourTeam5 from "src/images/ourTeam/img-5.jpg"
import ourTeam6 from "src/images/ourTeam/img-6.jpg"
import ourTeam8 from "src/images/ourTeam/img-8.jpg"
import ourTeam9 from "src/images/ourTeam/img-9.jpg"
import ourTeam10 from "src/images/ourTeam/img-10.jpg"
import ourTeam11 from "src/images/ourTeam/img-11.jpg"
import ourTeam13 from "src/images/ourTeam/img-13.jpg"
import ourTeam14 from "src/images/ourTeam/img-14.jpg"

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 8000, min: 4000 },
    items: 3,
    slidesToSlide: 1,
  },
  LargeDesktop: {
    breakpoint: { max: 4000, min: 2000 },
    items: 3,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 2000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 577 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 1,
  },
}

const defaultSlides = [
  {
    name: "Alex Khorunzhyi",
    position: "CEO",
    photo: ourTeam1,
  },
  {
    name: "Bogdan Shtybin",
    position: "CTO",
    photo: ourTeam2,
  },
  {
    name: "Arthur Goncharenko",
    position: "Head of HR Department",
    photo: ourTeam,
  },
  {
    name: "Ivan Karpus",
    position: "Mobile Team Lead",
    photo: ourTeam5,
  },
  {
    name: "Yaroslav Petrov",
    position: "Back-End Team Lead",
    photo: ourTeam10,
  },
  {
    name: "Igor Pilipenko",
    position: "Project Manager",
    photo: ourTeam6,
  },
  {
    name: "Samir Ismailov",
    position: "Project Manager",
    photo: ourTeam13,
  },
  {
    name: "Alexander Shevchenko",
    position: "Project Manager",
    photo: ourTeam8,
  },
  {
    name: "Yuliia Malyshenko",
    position: "Project Manager",
    photo: ourTeam14,
  },
  {
    name: "Viktoria Svishch",
    position: "Sales Manager",
    photo: ourTeam4,
  },
  {
    name: "Volodymyr Golemba",
    position: "Sales Manager",
    photo: ourTeam9,
  },
  {
    name: "Vladislav Baranov",
    position: "Sales Manager",
    photo: ourTeam11,
  },
]

const OurTeamCarousel = ({ className, ourTeam = defaultSlides }) => {
  return (
    <div className={className}>
      <MultiCarousel
        setup={responsive}
        sectionTitle={"Our Team"}
        buttonGroup={true}
      >
        {ourTeam.map((item, index) => (
          <OurTeamCarouselItem
            key={index}
            photo={item.photo}
            name={item.name}
            position={item.position}
          />
        ))}
      </MultiCarousel>
    </div>
  )
}

export default OurTeamCarousel
