import React, { memo } from "react"
import { Link } from "gatsby"
import classes from "./PreviewCases.module.scss"
import Marquee from "react-fast-marquee"
import "./OverrideTicker.scss"
import motorSich from "src/images/motorSich/case.png"
import uxr from "src/images/uxr/screen-preview.png"
import predictly from "src/images/predictly/screen-preview.png"
import tamil from "src/images/tamil/screen-preview.png"
import myLuminette from "src/images/myLuminette/screen-preview.png"
import pinTheLook from "src/images/pinTheLook/case.jpg"
import carSharing from "src/images/carSharing/screen-preview.jpg"

const PreviewCases = () => {
  const images = [
    motorSich,
    uxr,
    predictly,
    tamil,
    myLuminette,
    pinTheLook,
    carSharing,
  ]
  return (
    <Link to={`/portfolio`} id={"PCPortfolioMain"} aria-label="Go to portfolio">
      <Marquee
        className="tickerImagesContainer"
        gradient={false}
        pauseOnHover={true}
        speed={40}
        loop={0}
        delay={0}
        children={images.map(item => (
          <div className={classes.casesLink} key={item}>
            <img className={classes.previewImage} src={item} alt={"hi"} />
          </div>
        ))}
      />
    </Link>
  )
}

export default memo(PreviewCases)
