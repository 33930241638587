import React, { memo, useCallback, useRef } from "react"
import Image from "src/components/image"
import classes from "./HomePage.module.scss"
import ServicesGrid from "../layout/ServicesGrid/ServicesGrid"
import AccordionList from "../layout/AccordionList/AccordionList"
import ExperienceSection from "../layout/ExperienceSection/ExperienceSection"
import TickerRun from "../layout/TickerRun/TickerRun"
import BlocksWithStars from "../layout/BlocksWithStars/BlocksWithStars"
import SoundAnimation from "src/images/audio.gif"
import arrowDownIcon from "src/assets/icons/arrow-down.svg"
import SvgIcon from "src/components/SvgIcon"
import DynamicOurWorks from "src/components/HomePage/components/DynamicOurWorks/DynamicOurWorks"
import clsx from "clsx"
import OurTeamCarousel from "../Carousel/OurTeamCarousel/OurTeamCarousel"

const services = JSON.parse(`[
  {
    "id": 1,
    "title": "Dedicated Developers",
    "link": "/services/dedicated-developers"
  },
  {
    "id": 2,
    "title": "Web Development",
    "link": "/services/web-development"
  },
  {
    "id": 3,
    "title": "Mobile App Development",
    "link": "/services/mobile-development"
  },
  {
    "id": 4,
    "title": "Solutions Development",
    "link": "/services/solutions-development"
  }
]`)

const whyAreWe = JSON.parse(`[
  {
    "id": 1,
    "title": "Experience",
    "text":
      "The people you’ll meet in our team have solid working experience and are experts in business, design and technology. Together you'll discuss ideas and opportunities, and we'll deliver blueprints for the best solution."
  },
  {
    "id": 2,
    "title": "Result-oriented",
    "text":
      "We are not afraid of complicated tasks and are ready for new challenges. Our team is always focused on achieving the result in order to create a high quality solution for the client."
  },
  {
    "id": 3,
    "title": "Vast tech stack",
    "text":
      "Building a complex project calls for using a lot of advanced technologies. Our team is ready to provide you the top-notch tech solutions in order to meet all your requirements and expectations."
  },
  {
    "id": 4,
    "title": "Continuous support",
    "text":
      "You are not alone after the release stage. We are ready to maintain and scale your project. Our main goal is to build long-term trusted relationships with our customers."
  }
]`)

const interesting = JSON.parse(`[
  { "id": 1, "counter": 8, "subtitle": "Years of Experience" },
  { "id": 2, "counter": 86, "subtitle": "Full-Time Engineers" },
  { "id": 3, "counter": 172, "subtitle": "Successful Projects" },
  { "id": 4, "counter": 115, "subtitle": "Customers Worldwide" }
]`)

const reviews = JSON.parse(`[
  {
    "id": 1,
    "platform": "clutch",
    "stars": 5,
    "reviews": 11,
    "link": "https://clutch.co/profile/drumncode"
  },
  {
    "id": 2,
    "platform": "upwork",
    "stars": 5,
    "reviews": 91,
    "link": "https://www.upwork.com/ag/drumncode/"
  }
]`)

const HomePage = () => {
  const ref = useRef(null)

  const onExploreClick = useCallback(() => {
    if (ref.current)
      window.scrollTo({
        behavior: "smooth",
        top: ref.current.offsetTop - 80,
        left: 0,
      })
  }, [ref.current])

  return (
    <>
      <section className={clsx("container", classes.firstSection)}>
        <Image src="icon-smile.png" className={classes.rockstarIcon} />
        <h1 className={classes.title}>
          We are <br />
          rockstar developers
        </h1>
        <div className={classes.gif}>
          <img src={SoundAnimation} alt="Sound Animation" />
        </div>
        <div className={classes.explore} onClick={onExploreClick}>
          <p>Explore</p>
          <SvgIcon width={26} height={16} icon={arrowDownIcon} />
        </div>
      </section>

      <div className={classes.sliderBottom}>
        <div ref={ref}>
          <DynamicOurWorks />
        </div>
      </div>

      <div className="container">
        <ServicesGrid grid={services} />
        <AccordionList title="Why We" list={whyAreWe} />
        <ExperienceSection title="Interesting Data" list={interesting} />
      </div>
      <TickerRun yellow text="Let’s Talk" stringTicker />
      <div className="container">
        <BlocksWithStars titleSection="What Our Clients Say" list={reviews} />
      </div>
      <OurTeamCarousel />
      <TickerRun text="Get in Touch" yellow={false} stringTicker />
    </>
  )
}

export default memo(HomePage)
