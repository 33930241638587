import React from "react"
import classes from "./OurTeamCarouselItem.module.scss"

const OurTeamCarouselItem = ({ photo, name, position }) => {
  return (
    <div>
      <img
        src={photo}
        alt={photo}
        className={classes.image}
        draggable={false}
      />
      <h4 className={classes.name}>{name}</h4>
      <span className={classes.position}>{position}</span>
    </div>
  )
}

export default OurTeamCarouselItem
