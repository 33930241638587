import React, { useEffect } from "react"
import BasicLayout from "../components/layout/BasicLayout/BasicLayout"
import SEO from "../components/seo"
import HomePage from "../components/HomePage/HomePage"
import smoothScroll from "smoothscroll-polyfill"

const IndexPage = () => {
  useEffect(() => {
    smoothScroll.polyfill()
  }, [])
  return (
    <BasicLayout stickyBtn={false}>
      <SEO title="Home" />
      <HomePage />
    </BasicLayout>
  )
}

export default IndexPage
